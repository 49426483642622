import React, { useState, useEffect } from "react"
import imgOne from "@components/pageFunctionalities/pageWhatsappContact/images/hexagonoamarillo.png"
import imgTwo from "@components/pageFunctionalities/pageWhatsappContact/images/hexagonoazul.png"
import imgphone from "@components/pageFunctionalities/pageWhatsappContact/images/imgphone.jpg"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import Select from "react-select"

import atencion from "@components/pageHablemos/imgDemoandHablemos/operations/atencion.svg"
import marketing from "@components/pageHablemos/imgDemoandHablemos/operations/marketing.svg"
import ventas from "@components/pageHablemos/imgDemoandHablemos/operations/ventas.svg"

export const optionsOperations = [
  {
    id: "atencion_al_cliente", // Valor que va a en hubspot
    image: atencion,
    description: "Atención al cliente",
  },
  {
    id: "telemarketing", // Valor que va a en hubspot
    image: marketing,
    description: "Marketing",
  },
  {
    id: "televentas", // Valor que va a en hubspot
    image: ventas,
    description: "Ventas",
  },
]

const SliderItem = ({ data, onClickConfirm }) => {
  const { start, countries, numberWorkers, numberClient } = data

  //Estado valores inputs
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    pais: "default",
    workers: "default",
    apiWhatsapp: "default",
    clients: "default",
    operations: [],
    description: "",
    codeCountry: {
      value: "Perú",
      label: "Perú",
      image: "https://flagcdn.com/w320/pe.png",
      code: "+51",
    },
    phone: "",
    website: "",
  })

  const [blogChecked, setBlogChecked] = useState(false)

  //Estado validaciones
  const [validations, setValidations] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    pais: "",
    workers: "",
    apiWhatsapp: "",
    description: "",
    codeCountry: "",
    clients: "",
    phone: "",
    website: "",
    operations: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const Blacklist = [
      "@gmail.com",
      "@hotmail.com",
      "@yahoo.com",
      "@outlook.com",
      "yahoo.es",
      "yahoo.com.mx",
      "icloud.com",
      "gmail.co",
      "hotmail.es",
      "hotmail.it",
      "outlook.com",
      "outlook.es",
    ]

    const {
      name,
      lastname,
      email,
      pais,
      workers,
      apiWhatsapp,
      description,
      codeCountry,
      phone,
      website,
      clients,
      operations,
    } = values

    const validations = {
      name: "",
      lastname: "",
      email: "",
      company: "",
      pais: "",
      workers: "",
      description: "",
      codeCountry: "",
      phone: "",
      apiWhatsapp: "",
      website: "",
      clients: "",
      operations: "",
    }
    let isValid = true

    if (!name) {
      validations.name = "Campo requerido"
      isValid = false
    }
    if (name && !/^[a-zA-Z\s]+$/.test(name)) {
      validations.name = "Ingresar solo letras"
      isValid = false
    }
    if (!lastname) {
      validations.lastname = "Campo requerido"
      isValid = false
    }
    if (lastname && !/^[a-zA-Z\s]+$/.test(lastname)) {
      validations.lastname = "Ingresar solo letras"
      isValid = false
    }
    if (!email) {
      validations.email = "Campo requerido"
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "El formato debe ser el example@mail.com"
      isValid = false
    }

    if (Blacklist) {
      for (let i = 0; i < Blacklist.length; i++)
        if (email.toLowerCase().includes(Blacklist[i]) === true) {
          validations.email = "Ingrese un correo corporativo"
          isValid = false
        }
    }

    if (!phone) {
      validations.phone = "Campo requerido"
      isValid = false
    }

    if (operations.length < 1) {
      validations.operations = "Campo requerido"
      isValid = false
    }
    if (phone && !phone.match(/^[0-9]+$/)) {
      validations.phone = "Ingresar solo numeros"
      isValid = false
    }

    if (!company) {
      validations.company = "Campo requerido"
      isValid = false
    }
    if (company && !/^[a-zA-Z\s]+$/.test(company)) {
      validations.company = "Ingresar solo letras"
      isValid = false
    }
    if (pais === "default") {
      validations.pais = "Campo requerido"
      isValid = false
    }
    if (codeCountry === "default") {
      validations.codeCountry = "*"
      isValid = false
    }

    if (workers === "default") {
      validations.workers = "Campo requerido"
      isValid = false
    }

    if (apiWhatsapp === "default") {
      validations.apiWhatsapp = "Campo requerido"
      isValid = false
    }
    if (clients === "default") {
      validations.clients = "Campo requerido"
      isValid = false
    }
    if (website === "") {
      validations.website = "Campo requerido"
      isValid = false
    }
    if (description === "") {
      validations.description = "Campo requerido"
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }
    if (
      (name === "name" ||
        name === "lastname" ||
        name === "company" ) &&
      !/^[a-zA-Z\s]+$/.test(value)
    ) {
      message = "Ingresar solo letras"
    }
    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "El formato debe ser el example@mail.com"
    }

    if (value && name === "phone" && !/^[0-9]+$/.test(value)) {
      message = "Ingresar solo numeros"
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    name,
    lastname,
    email,
    company,
    pais,
    workers,
    description,
    phone,
    website,
    clients,
    apiWhatsapp,
    operations,
  } = values

  const {
    name: nameVal,
    lastname: lastnameVal,
    email: emailVal,
    company: companyVal,
    pais: paisVal,
    workers: workersVal,
    description: descriptionVal,
    codeCountry: codeCountryVal,
    phone: phoneVal,
    website: websiteVal,
    apiWhatsapp: apiWhatsappVal,
    clients: clientsVal,
    operations: operationsVal,
  } = validations

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  const [gclid, setGclid] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gclidParam = params.get('gclid');
    if (gclidParam) {
      setGclid(gclidParam);
    }
  }, []);

  //Funcion envio formulario
  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSpin(true)

      const body = {
        event: "Formulario-Contacto-Whatsapp",
      }
      if (window.dataLayer) {
        // para controlar si includeInDevelopment (gtm) es false
        window.dataLayer.push(body)
      }

      let success = true
      // Conexión con Backend
      try {
        const workersAllow4Backend =
          values.workers === "51 a más." ? "51 a más" : values.workers
        const clientsAllow4Backend =
          values.clients === "Más de 2000" ? "2000 a más" : values.clients
        const hasAPIWhatsapp = values.apiWhatsapp === "si"

        const params = new URLSearchParams()
        params.append("name", values.name)
        params.append("lastname", values.lastname)
        params.append("email", values.email)
        params.append("company", values.company)
        params.append("country", values.pais.label)
        params.append("description", values.description)
        params.append("codeCountry", values.codeCountry.code)
        params.append("phone", values.phone)
        params.append("website", values.website)
        params.append("workers", workersAllow4Backend) // Valores permitidos: '1-4','5-10','11-20','21-50','51 a más'
        params.append("blogChecked", blogChecked) // Boolean
        params.append("apiWhatsapp", hasAPIWhatsapp) // Boolean
        params.append("clients", clientsAllow4Backend) // Valores permitidos: '0-500','500-2000','2000 a más'
        params.append("operations", values.operations)
        params.append("type_action", "sendForm")
        params.append("gclid", gclid)
        await fetch(`/backend/wba.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log("error", error)
        success = false
      }
      if (success) {
        setTimeout(() => {
          setSpin(false)
          onClickConfirm()
        }, 2000)
        setValues({
          name: "",
          lastname: "",
          email: "",
          company: "",
          pais: "default",
          workers: "default",
          description: "",
          codeCountry: "default",
          phone: "",
          website: "",
        })
      }
    } else {
      return false
    }
  }

  const handleChangeCountry = value => {
    setValues({ ...values, codeCountry: value })
  }

  const customStyles = {
    option: provided => ({
      ...provided,
      width: 150,
      paddingTop: 10,
    }),
    menu: provided => ({
      ...provided,
      width: 150,
      marginTop: 0,
    }),
    control: provided => ({
      ...provided,
      borderRadius: 8,
      minHeight: 50,
      width: windowsWidth >= 350 ? "153px" : "100%",
      margin: windowsWidth >= 350 ? "10px 0px" : "10px 0px 0px",
    }),
  }
  const handleChangePais = value => {
    setValues({ ...values, pais: value })
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(350)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 464) {
      setWindowsWidth(270)
    } else {
      setWindowsWidth(350)
    }
  }, [])

  const customStylesPais = {
    option: provided => ({
      ...provided,
      width: windowsWidth,
      paddingTop: 10,
    }),
    menu: provided => ({
      ...provided,
      width: windowsWidth,
      marginTop: 0,
    }),
    placeholder: provided => ({
      ...provided,
      color: "#c7c7c7",
      marginLeft: 6,
      marginRight: 0,
      textAlign: "left",
    }),
    control: provided => ({
      ...provided,
      borderRadius: 8,
      minHeight: 51,
      width: windowsWidth >= 350 ? "100%" : "90%",
      margin: windowsWidth >= 350 ? "11px auto 0px" : "10px auto 0px",
    }),
  }

  const [selectedOptionsOperations, setSelectedOptionsOperations] = useState([])

  const handleOptionOperationsChange = option => {
    const isSelected = selectedOptionsOperations.includes(option.id)

    if (isSelected) {
      // Si ya está seleccionado, quitarlo del array
      setSelectedOptionsOperations(prevState =>
        prevState.filter(id => id !== option.id)
      )
    } else {
      // Si no está seleccionado, agregarlo al array
      setSelectedOptionsOperations(prevState => [...prevState, option.id])
      setValues({ ...values, operations: selectedOptionsOperations })
    }
  }

  useEffect(() => {
    setValues({ ...values, operations: selectedOptionsOperations })
  }, [selectedOptionsOperations])

  const [updateScroll, setupdateScroll] = useState(true)

  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY >= 800) {
        setupdateScroll(false)
      } else {
        setupdateScroll(true)
      }
    }
  }, [])

  return (
    <div className="container-component-form-contact-whatsapp">
      <div
        className="container-section-form-contact-whatsapp container-section-form-contact-whatsapp-scroll-mobile"
        style={{ position: updateScroll ? "fixed" : "absolute" }}
      >
        <div className="start-pricing-whatsapp container-imagenDescription-contact-whatsapp">
          <section>
            <img loading="lazy" src={start.logo} alt="hexagono" />
            <div>{start.title}</div>
            <p className="text-contacto-whatsapp">{start.textbody}</p>
          </section>
          <img
            className="img-contact-whatsapp"
            loading="lazy"
            src={imgphone}
            alt="whatsapp"
          />
        </div>
        <div
          className="section-contacto section-contacto-contact-whatsapp"
          style={{ visibility: "hidden" }}
        />
      </div>

      <div className="container-section-form-contact-whatsapp">
        <div className="start-pricing-whatsapp start-pricing-whatsapp-scroll-mobile">
          <section>
            <img loading="lazy" src={start.logo} alt="hexagono" />
            <div className="title-start-pricing-whatsapp">{start.title}</div>
            <p className="text-contacto-whatsapp">{start.textbody}</p>
          </section>
          <img
            className="img-contact-whatsapp"
            loading="lazy"
            src={imgphone}
            alt="whatsapp"
          />
        </div>
        <section className="section-contacto section-contacto-contact-whatsapp">
          <img
            loading="lazy"
            src={imgOne}
            alt="whatsapp"
            className="amarillo"
          />
          <div className="container-section-form-contact-whatsapp-scroll">
            <div className="container-heroebook-suscribirtwo">
              <p className="title-form-contact-price-whatsapp">
                Solicita acceso
              </p>
              <p className="container-form-partners-titles">Tus datos</p>

              <div className="container-section-form-contact-whatsapp-inputs">
                <div className="container-section-form-contact-whatsapp-inputs_div">
                  <input
                    className="input-hero"
                    placeholder="Nombres"
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    onBlur={validateOne}
                  />
                  <div className="error">{nameVal}</div>
                </div>

                <div className="container-section-form-contact-whatsapp-inputs_div">
                  <input
                    className="input-hero"
                    placeholder="Apellidos"
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={handleChange}
                    onBlur={validateOne}
                  />
                  <div className="error">{lastnameVal}</div>
                </div>
              </div>

              <div>
                <input
                  className="input-hero"
                  placeholder="Correo corporativo"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{emailVal}</div>
              </div>

              <div className="inputs-form">
                <div className="code-phone">
                  <div>
                    <Select
                      options={countries}
                      defaultValue={{
                        value: "Perú",
                        label: "Perú",
                        image: "https://flagcdn.com/w320/pe.png",
                        code: "+51",
                      }}
                      onChange={value => handleChangeCountry(value)}
                      placeholder={"Codigo"}
                      styles={customStyles}
                      formatOptionLabel={country => (
                        <div className="country-option">
                          <img src={country.image} alt="country" />
                          <span>{country.code}</span>
                        </div>
                      )}
                    />
                    <div className="error">{codeCountryVal}</div>
                  </div>
                  <div>
                    <input
                      className="input-hero"
                      placeholder="Telefono"
                      type="text"
                      name="phone"
                      value={phone}
                      onChange={handleChange}
                      onBlur={validateOne}
                    />
                    <div className="error">{phoneVal}</div>
                  </div>
                </div>
              </div>
              <p className="container-form-partners-titles">
                Datos de tu empresa
              </p>
              <div className="container-section-form-contact-whatsapp-inputs">
                <div className="container-section-form-contact-whatsapp-inputs_div">
                  <input
                    className="input-hero"
                    placeholder="Nombre de la Empresa"
                    type="text"
                    name="company"
                    value={company}
                    onChange={handleChange}
                    onBlur={validateOne}
                  />
                  <div className="error">{companyVal}</div>
                </div>

                <div className="container-select-paises container-section-form-contact-whatsapp-inputs_div">
                  <Select
                    options={countries}
                    value={pais}
                    onChange={value => handleChangePais(value)}
                    placeholder={" País de la empresa"}
                    styles={customStylesPais}
                    formatOptionLabel={country => (
                      <div className="country-option">
                        <img src={country.image} alt="country" />
                        <span>{country.label}</span>
                      </div>
                    )}
                  />

                  <div className="error">{paisVal}</div>
                </div>
              </div>
              <div>
                <input
                  className="input-hero"
                  placeholder="Website"
                  type="text"
                  name="website"
                  value={website}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{websiteVal}</div>
              </div>
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      apiWhatsapp === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="apiWhatsapp"
                    value={apiWhatsapp}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={apiWhatsapp}
                  >
                    <option value="default" disabled hidden>
                      ¿Trabajan con la API de WhatsApp Business?
                    </option>
                    <option key="si" value="si">
                      Sí trabajamos con la API de WhatsApp Business
                    </option>
                    <option key="no" value="no">
                      No trabajamos con la API de WhatsApp Business
                    </option>
                  </select>
                  <i></i>
                </div>
                <div className="error">{apiWhatsappVal}</div>
              </div>
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      clients === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="clients"
                    value={clients}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={clients}
                  >
                    <option value="default" disabled hidden>
                      N° de clientes contactados al mes
                    </option>
                    {numberClient.map((element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{clientsVal}</div>
              </div>
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      workers === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="workers"
                    value={workers}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={workers}
                  >
                    <option value="default" disabled hidden>
                      Nro. de agentes que usarán la plataforma
                    </option>
                    {numberWorkers.map((element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{workersVal}</div>
              </div>
              <div className="container-newform-demo-hablemos--container_checkbox">
                <p className="container-newform-demo-hablemos--inputContainer_label">
                  ¿Cuál tipo de operación o caso de uso buscas mejorar?
                </p>
                <div className="container-newform-demo-hablemos--container_checkbox-channels">
                  {optionsOperations.map(option => (
                    <div
                      key={option.id}
                      className={`container-newform-demo-hablemos--container_checkbox-channels-card ${
                        selectedOptionsOperations.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        value={operations}
                        id={`operation${option.id}`}
                        checked={selectedOptionsOperations.includes(option.id)}
                        onChange={() => handleOptionOperationsChange(option)}
                      />
                      <label htmlFor={`operation${option.id}`}>
                        <div className="container-newform-demo-hablemos--container_checkbox-channels-card-info">
                          <img src={option.image} alt={option.title} />

                          <p>{option.description}</p>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                <br />
                <div className="error">{operationsVal}</div>
              </div>
              <div>
                <p className="container-newform-demo-hablemos--inputContainer_label">
                  ¿Cómo deseas que te ayude el API de WhatsApp Business en tu
                  empresa?
                </p>
                <textarea
                  className="textarea-form-whatsapp"
                  placeholder="Escribir aquí..."
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleChange}
                  onBlur={validateOne}
                  defaultValue={description}
                />
                <div className="error">{descriptionVal}</div>
              </div>
              <div className="checkox-demo">
                <input
                  type="checkbox"
                  value={blogChecked}
                  onChange={() => setBlogChecked(!blogChecked)}
                  defaultChecked={blogChecked}
                />
                <p>
                  Deseo recibir artículos del blog de Beex{" "}
                  <span role="img" aria-label="Love">
                    😍
                  </span>
                </p>
              </div>
            </div>

            <div style={{ marginTop: "31px", textAlign:"center" }}>
              <button
                className="btn-start-pricing"
                onClick={handleSubmit}
                style={{ display: " inline-flex", gap: "12px" }}
              >
                Solicitar acceso
                {spin ? (
                  <Loader type="Oval" color="#5e5e5e" height={20} width={20} />
                ) : (
                  ""
                )}
                  
              </button>
            </div>
            <p className="link-ebook">{start.link}</p>
          </div>
          <img loading="lazy" src={imgTwo} alt="hexagono" className="azul" />
        </section>
      </div>

      <div className="update-scroll" />
    </div>
  )
}

export default SliderItem
